<template>
  <div>
    <div
      class="home-card"
      :class="Skin"
    >
      <h1>{{ H1 }}</h1>
      <img
        :src="require(`@/assets/images/code/${Img}.png`)"
        alt="top"
      >
      <h2>{{ H2 }}</h2>
      <div
        class="card-text">
        <p
          v-for="(content, i) in Contents"
          :key="i"
          class="content"
          v-html="content"
        />
      </div>
      <a :href="Href">
        <button >View</button>
      </a>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    h1: { type: String, default: '' },
    h2: { type: String, default: '' },
    img: { type: String, default: '' },
    contents: { type: Array, default: () => [] },
    href: { type: String, default: '' },
    skin: { type: String, default: '' },
  },
  setup(props) {
    const state = reactive({
      H1: props.h1,
      H2: props.h2,
      Img: props.img,
      Contents: props.contents.map(content => content.replace('\n', '<br />')),
      Href: props.href,
      Skin: props.skin,
    })
    return toRefs(state)
  },
}
</script>
