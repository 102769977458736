<template>
  <div>
    <div class="news-contents">
      <div class="news-box">
        <img
          src="@/assets/images/code/web-news.png"
          alt="newsImg"
        >
        <div class="news-top">
          <slot name="newsTitle" />
          <slot name="newsContent" />
        </div>
        <div class="text-overline">
          <slot name="newsDate" />
          <slot name="newsCompany" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
}
</script>
