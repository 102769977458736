<template>
  <div class="card-row">
    <div
      v-for="(profile, i) in Profiles"
      :key="i"
      class="team-cards"
    >
      <img
        :src="require(`@/assets/images/code/${profile.img}`)"
        alt="profile"
      >
      <h5>{{ profile.name }}</h5>
      <p>{{ profile.career }}</p>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from '@vue/composition-api'

export default {
  props: {
    profiles: { type: Array, default: () => [] },
  },
  setup(props) {
    const state = reactive({
      Profiles: props.profiles,
    })
    return toRefs(state)
  },
  methods: {
  },
}
</script>
