<template>
  <div class="e-content">
    <div id="ComponentCharacteristics">
      <h1>Card</h1>
      <p>카드 컴포넌트는 단일 주제에 대한 내용과 작업이 포함되며 3가지 유형으로 나누어져 있습니다.
      </p>
      <b-card title="Component Characteristics">
        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="props"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">Vue의 모든 컴포넌트 인스턴스에는 각자의 자체 격리된 범위(scope)가 있습니다. 이 때문에 하위 컴포넌트의 템플릿에서는 상위 데이터를 직접 참조할
            수 없는데, props 를 사용하면 하위 컴포넌트로 데이터를 전달할 수 있습니다.
            <br>
            <h5 class="my-2">사용 예시</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codePropsSample2 }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="Composition API"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">Vue의 기존 option API는 컴포넌트가 커지면서 수많은 데이터가 공존했다면 methods, computed도 그만큼 많아지고, data가 어디서
            어떤 함수에 의해 변하는지 분산되어 추적이 어려워 집니다. 그에 따라 유지보수가 어려워집니다.
            composition API를 사용함으로 setup 함수에 데이터가 그룹핑 되어 보다 용이하게 데이터의 흐름을 파악하고 유지보수와 재사용하기가 용이합니다.
            <br><br>
            <code>setup</code> 함수에 data, method, lifecycle hooks 등을 등록하여 리액트의 훅과 같이 사용할 수 있습니다. <span class="font-weight-bold">props</span>와 <span class="font-weight-bold">state</span>로 데이터를 관리합니다.
            <h5 class="my-2">사용 예시</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codeCompositionAPI2 }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>

        <b-card header-bg-variant="dark" header-text-variant="white" header-font-weight="bold"
                header="v-slot"
                border-variant="dark"
                vertical-align="center">
          <b-card-text class="mt-2">Slot은 자식(하위) 컴포넌트를 그대로 유지하면서 다른 부모(상위) 컴포넌트들마다 다른 내용을 적용하고 싶을 때 사용합니다. 기본적으로 부모 컴포넌트의 요소를 자식 컴포넌트에 내려받는 방식으로 사용합니다.
            <br>
            <h5 class="my-2">사용 방법</h5>
            <div class="card-code">
            <pre class="px-2 d-flex align-items-center">
              <code class="language-javascript">
                {{ codeSlot }}
              </code>
            </pre>
            </div>
          </b-card-text>
        </b-card>
      </b-card>

      <b-card-code id="CardHome" title="Card-Home">
        <p>홈 첫 번째 섹션에서 사용되는 카드입니다. NFT, Node, DeFi 페이지의 간략한 정보와 하이퍼링크를 제공합니다.</p>
        <div class="mb-3 d-flex justify-content-center">
          <card-home
              h1="Node"
              h2="Staking"
              img="node"
              :contents="['Staking Tokens','Listing in Server','Long-term maintenance,\nupdate the network']"
              href=""
              skin="node"
          />
        </div>
        <p class="mb-2">◼ 컴포넌트로 전달 받는 속성은 다음과 같습니다.</p>
        <p class="mb-1"><span class="font-weight-bold">h1:</span> 메인 제목입니다.<br>
          <span class="font-weight-bold">h2:</span> 서브 제목입니다.<br>
          <span class="font-weight-bold">img:</span> 상단 배경 이미지입니다.<br>
          <span class="font-weight-bold">contents:</span> 카드 내용입니다.<br>
          <span class="font-weight-bold">href:</span> 하이퍼링크 경로입니다.<br>
          <span class="font-weight-bold">skin:</span> 카드 스킨입니다.
        </p>
        <template #code>
          {{ codeCardNews }}
        </template>
      </b-card-code>

      <b-card-code id="CardNews" title="Card-News">
        <p>홈 두 번째 섹션에서 사용되는 카드입니다. 뉴스 기사에 대한 정보를 제공합니다.</p>
        <div class="mb-3 d-flex justify-content-center">
          <card-news>
            <h5 slot="newsTitle">
              {{ newsTitle }}</h5>
            <p slot="newsContent">
              {{ newsContent }}
            </p>
            <p slot="newsDate">
              {{ newsDate }}
            </p>
            <p slot="newsCompany">
              {{ newsCompany }}
            </p>
          </card-news>
        </div>
        <p class="mb-2">◼ <code>slot</code>으로 newsTitle, newsContentm, newsDate, newsCompany를 전달합니다.</p>
        <template #code>
          {{ codeCardHome }}
        </template>
      </b-card-code>

      <b-card-code id="CardProfile" title="Card-Profile">
        <p>홈 네 번째 섹션에서 사용되는 카드입니다. 팀원들의 프로필 정보를 제공합니다.</p>
        <div class="mb-3 d-flex justify-content-center">
          <card-profile :profiles="profiles" />
        </div>
        <p class="mb-2">◼ 컴포넌트로 전달 받는 속성은 다음과 같습니다.</p>
        <p class="mb-1"><span class="font-weight-bold">h1:</span> 메인 제목입니다.<br>
          <span class="font-weight-bold">profiles:</span> 프로필 이름, 사진, 커리어 정보가 들어있습니다.
        </p>
        <template #code>
          {{ codeCardProfile }}
        </template>
      </b-card-code>

    </div>
  </div>
</template>

<script>
import {BCard, BCardText, BImg} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  codeCardHome, codeCardNews, codeCardProfile,
  codeCompositionAPI2,
  codePropsSample2, codeSlot,

} from './code'
import CardHome from '@/components/Card/CardHome.vue'
import CardNews from '@/components/Card/CardNews.vue'
import CardProfile from '@/components/Card/CardProfile.vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'


export default {
  components: {
    CardProfile,
    CardNews,
    CardHome,
    BCardCode,
    BCard,
    BCardText,
    BImg,
    Prism,
  },
  data() {
    return {
      rightNavMenu: ['ComponentCharacteristics', 'CardHome', 'CardNews', 'CardProfile'],
      codePropsSample2,
      codeCompositionAPI2,
      codeSlot,
      codeCardHome,
      codeCardNews,
      codeCardProfile,
      newsTitle: '엔터블록, 페리티가 주도한 SDP에 합류',
      newsContent: '국내 엔터프라이즈 블록체인 기술 제공사인 엔터블록이 이더리움 클라이언트 개발사인 페리티 테크놀로지(Parity Technologies)가 주도한 서브스트레이트 딜리버리 파트너스(Substrate Delivery Partners)에 합류한다고 6월 11일(현지시간) 밝혔다.\n'
          + '서브스트레이트 딜리버리 파트너스는 페리티가 개발한 블록체인 프레임워크인 서브스트레이트를 사용하는 기술 지원 프로그램이다. 서브스트레이트는 블록체인의 설계를 유연성 있게 만들 수 있는 프레임워크이다. 일반적인 수준으로 맞출 수 있다는 것도 특징이다. 유명 프로젝트인 폴카닷,폴리매스, 제로엑스등 100개의 프로젝트들이 서브스트레이트 기반으로 개발되고 있다.\n'
          + '엔터블록은 이번 합류를 통해 서브스트레이트 기반으로 엔터프라이즈 블록체인을 위한 프레임워크를 제공한다. 기업들의 비즈니스 로직에 따른 블록체인 플랫폼 서비스를 지원할 계획이다. 또한 폴카닷 네트워크 간 상호운용 할 수 있는 브릿지 솔루션을 구축할 예정이다. 엔터블록은 현재 엔터프라이즈 블록체인 솔루션을 제공하고 있으며, 디파이(DeFi) 프로젝트를 개발하고 있는 그로우파이(GrowFi)를 운영 중이다.',
      newsDate: '2020-06-12',
      newsCompany: 'Cobak',
      profiles: [
        {
          img: 'photo-park.png',
          name: 'Kn.Park',
          career: 'ex-Bithumb coin(20+) wallet, sign server development1 & node operation guide. Samsung Galaxy S S-memo development1.',
        },],
    }
  },
  created() {
    this.$store.commit('verticalMenu/UPDATE_RIGHT_NAV_MENU', this.rightNavMenu)
  },
}
</script>

